import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import _, { debounce } from 'lodash'; 
import styled from 'styled-components';
import {
  Box,
  Typography,
  Collapse,
  List,
  ListItem,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { API_PREFIX } from '../../constants/urls';
import { formatTime, formatDate, formatMessageDate } from '../../utils/formatDateTimeUtils';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Details Icons
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import { MinorStat, MinorStatText } from '../theme/LeadDisplayStyles';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface Message {
  id: string;
  text: string;
  postedAt: string;
  status: string;
  sender: string;
  to: string;
}

interface Lead {
  id: string;
  year: string;
  make: string;
  model: string;
  leadStatus: string;
}

interface CustomerDetails {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  postcode: string;
  leads: Lead[];
}

interface MessageGroup {
  phoneNumber: string;
  latestMessage: Message;
  messages: Message[];
  unreadCount: number;
  customerDetails?: CustomerDetails;
}

const ExpandableContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded: boolean }>`
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 8px;
  background-color: ${(props) => (props.expanded ? 'transparent' : 'white')};

  &:hover {
    background-color: ${(props) => (props.expanded ? 'transparent' : '#f9f9f9')};
  }
`;

const ContentBox = styled(Box)`
&&{
  display: flex;
  @media all and (max-width: 799px){
    display: block;
  }
}
`

const ContentSection = styled(Box)`
&&{
  width: 42%;
  @media all and (max-width: 799px){
    width: 100%;
  }
}
`

const MessageSection = styled(Box)`
&&{
  width: 58%;
  @media all and (max-width: 799px){
    width: 100%;
    margin-top: 10px;
  }
}
`;
const MessageScroll = styled(Box)`
  min-height: 180px;
  max-height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #E9E9E9;
  border-radius: 14px;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 8px;
  @media all and (max-width: 799px) {
    width: 100%;
  }
`
const MessageList = styled(List)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  min-height: 180px;
`;

const MessageBubble = styled.div<{ $ownMessage: boolean }>`
  position: relative;
  max-width: 87%;
  margin: ${props => (props.$ownMessage ? '0 0 2px auto' : '0 auto 2px 0')};
  color: ${props => (props.$ownMessage ? '#FFF' : '#000')};
  background-color: ${props => (props.$ownMessage ? '#007FFF' : '#CFE1FF')};
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 13px;
  line-height: 1.2;
`;

const Subtext = styled.div<{ $ownMessage: boolean }>`
  display: flex;
  color: ${props => (props.$ownMessage ? '#FFF' : '#000')};
  justify-content: ${props => (props.$ownMessage ? 'flex-end' : 'flex-start')};
  font-size: 10px;
  margin-top: 4px;
`;

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#007FFF',
    },
    '&:hover fieldset': {
      borderColor: '#007FFF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#007FFF',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#007FFF',
    background: '#FFF',
    padding: '0 6px 0 0'
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#007FFF',
  },
});

const StyledMessageButton = styled(Button)`
  && {
    width: 100px;
    margin: 8px 0 0;
    color: #007FFF;
    border: 2px solid #007FFF;
    font-weight: 600;
    float: right;
    &:hover {
      color: white;
      background: #007FFF;
    }
  }
`;

const VehicleLeadBox = styled(Box)`
  display: inline-block;
  position: relative;
  margin: 5px 5px 5px 0;
  padding: 5px 10px;
  border: 1px solid grey;
  border-radius: 12px;
  cursor: pointer;
  &:after: {
    clear: both;
  }
`

const SMSHistory: React.FC = () => {
  const [messageGroups, setMessageGroups] = useState<MessageGroup[]>([]);
  const [filteredMessageGroups, setFilteredMessageGroups] = useState<MessageGroup[]>([]);
  const [itemsExpanded, setItemsExpanded] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastFetchedDate, setLastFetchedDate] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showReplied, setShowReplied] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState<string | null>(null);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string | null>(null);
  const [isSending, setIsSending] = useState(false);
  
  // Reference for scrolling to the bottom
  const messageListRef = useRef<HTMLUListElement>(null);

  const CLICKSEND_NUMBER = process.env.REACT_APP_CLICKSEND_NUMBER || "+61419095228";

  useEffect(() => {
  if (searchQuery.length >= 0) { // Allow any query length to trigger
    setItemsExpanded([]);
    setMessageGroups([]);
    setLastFetchedDate(null);
    setHasMore(true);
    fetchMessages(true);
  }
}, [searchQuery]);

  useEffect(() => {
    const filtered = showReplied
      ? messageGroups.filter((group) =>
          group.messages.some((msg) => msg.status === 'received')
        )
      : messageGroups;
    setFilteredMessageGroups(filtered);
  }, [messageGroups, showReplied]);

  useEffect(() => {
    if (itemsExpanded.length && messageListRef.current) {
 
      // Wait until messages are fetched for the expanded group
      if (messageGroups?.length > 0) {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }
    }
  }, [itemsExpanded, messageGroups]);
  
  useEffect(() => {
    const handleScroll = () => {
      if (loading || !hasMore) return;
  
      const scrollPosition = window.scrollY + window.innerHeight;
      const pageHeight = document.body.scrollHeight;
      const threshold = pageHeight - 300;
  
      const shouldFetch = scrollPosition >= threshold || pageHeight <= window.innerHeight;
  
      if (shouldFetch) {
        fetchMessages();
      }
    }
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore, filteredMessageGroups.length]);


  const fetchMessages = async (reset = false) => {
    if (loading || (!hasMore && !reset)) return;

    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_PREFIX}/sms-summary`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          lastFetchedDate: reset ? null : lastFetchedDate,
          query: searchQuery,
          limit: 200,
        },
      });

      const { messages, lastFetchedDate: newLastFetchedDate, hasMore: more } = response.data;

      const groupedMessages = groupMessagesByPhoneNumber(messages);

      setMessageGroups((prev) => {
        const messageMap = new Map<string, MessageGroup>();

        // Add existing message groups to the map
        prev.forEach((group) => messageMap.set(group.phoneNumber, group));

        // Add new groups, overwriting any duplicates
        groupedMessages.forEach((group) => messageMap.set(group.phoneNumber, group));

        // Convert back to an array and return
        return Array.from(messageMap.values());
      });

      setLastFetchedDate(newLastFetchedDate);
      setHasMore(more);
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedResetQuery = debounce(() => {
    resetQuery();
  }, 200);

  const resetQuery = async () => {
    setSearchQuery('');
  }

  const groupMessagesByPhoneNumber = (messages: Message[]): MessageGroup[] => {
    const groups = messages.reduce((acc, message) => {
      const phoneNumber = message.sender === 'CBO' ? message.to : message.sender;
  
      if (!acc[phoneNumber]) {
        acc[phoneNumber] = {
          phoneNumber,
          messages: [],
          latestMessage: message,
          unreadCount: 0,
        };
      }
  
      acc[phoneNumber].messages.push(message);
      if (message.status === 'received') acc[phoneNumber].unreadCount += 1;
  
      if (message.postedAt > acc[phoneNumber].latestMessage.postedAt) {
        acc[phoneNumber].latestMessage = message;
      }
  
      return acc;
    }, {} as Record<string, MessageGroup>);
  
    // Sort each group's messages by `postedAt` in ascending order
    Object.values(groups).forEach((group) => {
      group.messages.sort((a, b) => new Date(a.postedAt).getTime() - new Date(b.postedAt).getTime());
    });
  
    return Object.values(groups);
  };

  const fetchFullSmsHistory = async (phoneNumber: string) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_PREFIX}/get-sms`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { phone: phoneNumber },
      });
  
      const { messages } = response.data;
  
      // Update the specific message group with the full conversation history
      setMessageGroups(prevGroups =>
        prevGroups.map(group =>
          group.phoneNumber === phoneNumber
            ? { ...group, messages }
            : group
        )
      );
  
    } catch (error) {
      console.error('Error fetching full SMS history:', error);
    }
  };  
  
  const fetchCustomerDetails = async (phoneNumber: string) => {
    try {
      const response = await axios.get(`${API_PREFIX}/get-customer-details`, {
        params: { phone: phoneNumber },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const { customerDetails, enquiries } = response.data;
  
      setMessageGroups(prevGroups =>
        prevGroups.map(group =>
          group.phoneNumber === phoneNumber
            ? { ...group, customerDetails: { ...customerDetails, leads: enquiries } }
            : group
        )
      );
  
      // Set the lead ID from the fetched details
      if (enquiries?.[0]?.id) {
        setSelectedLeadId(enquiries[0].id);
      } else {
        setSelectedLeadId(null);
      }
    } catch (error) {
      console.error('Error fetching customer details:', error);
    }
  };

  const handleToggle = (phoneNumber: string) => {
    setItemsExpanded(prevExpanded =>
      prevExpanded.includes(phoneNumber)
        ? prevExpanded.filter(num => num !== phoneNumber)
        : [...prevExpanded, phoneNumber]
    );
  
    if (!itemsExpanded.includes(phoneNumber)) {
      fetchFullSmsHistory(phoneNumber);
      fetchCustomerDetails(phoneNumber);
    } else {
      setSelectedLeadId(null); // Clear the lead ID if collapsing
    }
  };

  const handleSendSMS = (phoneNumber: string) => {
    setSelectedPhoneNumber(phoneNumber);
    setDialogOpen(true);
  };

  const sendSMS = async (leadId: string, to: string, message: string, sender: string) => {
    try {
      console.log('Sending SMS:', leadId, to, message, sender )
      const response = await axios.post(
        `${API_PREFIX}/send-sms`,
        { leadId, to, message, sender },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const confirmSendSMS = async () => {
    setDialogOpen(false);
    setIsSending(true);

    // Scroll to show the loader
    setTimeout(() => {
      if (messageListRef.current) {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }
    }, 10); // No delay needed, immediate scroll
  
    if (!newMessage.trim() || !selectedPhoneNumber || !selectedLeadId) {
      console.error('Cannot send SMS: Missing required fields');
      setIsSending(false);
      return;
    }
  
    try {

      // Call the sendSMS API
      const sentMessage = await sendSMS(selectedLeadId, selectedPhoneNumber, newMessage, 'CBO');
  
      // Add the new message to the UI only after confirmation
      const newMessageData: Message = {
        id: sentMessage.id,
        text: newMessage,
        sender: 'CBO',
        to: selectedPhoneNumber,
        postedAt: new Date().toISOString(),
        status: 'sent',
      };
  
      setMessageGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.phoneNumber === selectedPhoneNumber
            ? {
                ...group,
                messages: [...group.messages, newMessageData],
                latestMessage: newMessageData,
              }
            : group
        )
      );
    } catch (error) {
      console.error('Failed to send SMS:', error);
  
      // Show a failed state or error message if the send fails
      const failedMessage: Message = {
        id: uuidv4(),
        text: newMessage,
        sender: 'CBO',
        to: selectedPhoneNumber,
        postedAt: new Date().toISOString(),
        status: 'failed',
      };
  
      setMessageGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.phoneNumber === selectedPhoneNumber
            ? {
                ...group,
                messages: [...group.messages, failedMessage],
              }
            : group
        )
      );
    } finally {
      setNewMessage(''); // Clear the input field
      setIsSending(false);
  
      // Scroll to the bottom
      setTimeout(() => {
        if (messageListRef.current) {
          messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
      }, 200); // Small delay to ensure the DOM has updated
    }
  };
  


  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleShowRepliedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowReplied(event.target.checked);
  };

  return (
    <Box>
      <FormControlLabel
          control={
            <Checkbox
              checked={showReplied}
              onChange={handleShowRepliedChange}
              color="primary"
              style={{ margin: '-2px 0 0 0'}}
            />
          }
          label="Has Replied"
          style={{ float: 'right', marginBottom: '10px', userSelect: 'none' }}
        />
      <Box display="flex" alignItems="center" sx={{ width: '100%', mb: 1 }}>
        <TextField
          fullWidth
          placeholder="Search by phone number"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          sx={{ mr: 1}}
        />
        <Button
          variant="outlined"
          onClick={debouncedResetQuery}
          style={{
            height: '58px', lineHeight: '44px',
          }}
        >
          Reset
        </Button>
      </Box>

      {filteredMessageGroups.map(group => {
        const latestMessageText = group.latestMessage.text.slice(0, 160) + (group.latestMessage.text.length > 160 ? '...' : '');
        const customerName = group.customerDetails
          ? `${group.customerDetails.firstName} ${group.customerDetails.lastName}`
          : '';

        return (
          <ExpandableContainer key={`${group.phoneNumber}-${group.latestMessage.id}`} expanded={itemsExpanded.includes(group.phoneNumber)} as="div">
            <Box onClick={() => handleToggle(group.phoneNumber)}>
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Box style={{ display: 'flex', alignItems: 'left', justifyContent: 'flex-start'}}>
                  <Box style={{ display: 'flex', alignItems: 'center', backgroundColor: group.latestMessage.sender === 'CBO' ? 'transparent' : '#007FFF',
                    border: group.latestMessage.sender === 'CBO' ? '1px solid #007FFF' : '0 none', borderRadius: '12px', padding: '2px 10px 0 0',
                    marginBottom: '5px' 
                  }}>
                    {group.latestMessage.sender !== 'CBO' ? (
                      <CallReceivedIcon style={{ color: 'white', width: '24px', marginLeft: '4px' }} />
                    ) : (
                      <CallMadeIcon style={{ color: '#007FFF', width: '24px', marginLeft: '4px' }} />
                    )}
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: group.latestMessage.sender === 'CBO' ? '#007FFF' : 'white', margin: '2px 0 0 4px' }}>{group.phoneNumber}</Typography>
                  </Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'rgba(0,0,0,0.8)', margin: '4px 0 0 12px' }}>{customerName}</Typography>
                </Box>
                {itemsExpanded.includes(group.phoneNumber) ? (<KeyboardArrowUpIcon style={{ width: '28px' }} /> ) : (<KeyboardArrowDownIcon style={{ width: '28px' }} /> )}
              </Box>
              {!itemsExpanded.includes(group.phoneNumber) && (
                <Typography variant="body2" sx={{ margin: '4px 0', color: group.unreadCount > 0 ? 'secondary.main' : 'text.primary' }}>
                  {latestMessageText}
                </Typography>
              )}
              <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary' }}>
                {formatDate(new Date(group.latestMessage.postedAt))}, {formatTime(new Date(group.latestMessage.postedAt))}
              </Typography>
            </Box>

            <Collapse in={itemsExpanded.includes(group.phoneNumber)} timeout="auto" unmountOnExit style={{ cursor: 'initial' }}>
              <ContentBox gap={4} mt={2}>
                <ContentSection>
                  <Box>
                  { group.customerDetails ? (
                    <Typography variant="body1" fontWeight="bold" style={{ fontSize: '16px', color: 'rgba(0,0,0,0.8)'}}>Customer matched:</Typography>
                  ) : (<Typography variant="body2">No matching customer found.</Typography>)}
                  { customerName ? (
                    <MinorStat>
                      <PersonIcon style={{ width: '15px', height: '16px', margin: '-2px 5px 2px 0' }} />
                      <MinorStatText>
                        {customerName}
                      </MinorStatText>
                    </MinorStat>
                    ) : ('')}
                  { group.customerDetails?.phone ? (
                    <MinorStat>
                      <PhoneIcon style={{ width: '15px', height: '16px', margin: '-2px 5px 2px 0' }} />
                      <MinorStatText>
                        <a href={`tel:${group.customerDetails?.phone}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                          {group.customerDetails?.phone}
                        </a>
                      </MinorStatText>
                    </MinorStat>
                  ) : ('')}
                  { group.customerDetails?.email ? (
                    <MinorStat>
                      <EmailIcon style={{ width: '15px', height: '16px', margin: '-2px 5px 2px 0' }} />
                      <MinorStatText>
                        <a href={`mailto:${group.customerDetails?.email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                          {group.customerDetails?.email}
                        </a>
                      </MinorStatText>
                    </MinorStat>
                  ) : ('')}
                  { group.customerDetails?.postcode ? (
                    <MinorStat>
                      <PlaceIcon style={{ width: '15px', height: '16px', margin: '-2px 5px 2px 0' }} />
                      <MinorStatText>
                        <a href={`mailto:${group.customerDetails?.postcode}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                          {group.customerDetails?.postcode}
                        </a>
                      </MinorStatText>
                    </MinorStat>
                  ) : ('')}
                </Box>
                { group.customerDetails?.leads ? (
                    <Typography variant="body1" mt="10px" fontWeight="bold" style={{ fontSize: '16px', color: 'rgba(0,0,0,0.8)'}}>Vehicles matched:</Typography>
                  ) : (<Typography variant="body2">No vehicles found.</Typography>)}
                {group.customerDetails?.leads ? (
                  group.customerDetails.leads.map((lead) => (
                    <VehicleLeadBox style={{ borderColor: lead.leadStatus === 'New' ? '#B9E123' : lead.leadStatus === 'Working' ? '#B9E123' : lead.leadStatus === 'Appointment' ? '#B9E123' : lead.leadStatus === 'Purchased' ? 'grey' : lead.leadStatus === 'Dead' ? 'orange' : lead.leadStatus === 'Delete' ? '#f22020' : lead.leadStatus === 'Dead' ? '#f22020' : 'grey'}}
                    onClick={() => window.open(`/dashboard?s=${lead.id}`, '_blank')}
                    >
                      <Typography key={lead.id} variant="body2" sx={{ mb: 0 }}>
                      {lead.year} {lead.make} {lead.model} 
                      <span style={{ margin: '0 7px', fontWeight: 'bold', color: lead.leadStatus === 'New' ? '#B9E123' : lead.leadStatus === 'Working' ? '#B9E123' : lead.leadStatus === 'Appointment' ? '#B9E123' : lead.leadStatus === 'Purchased' ? 'grey' : lead.leadStatus === 'Dead' ? 'orange' : lead.leadStatus === 'Delete' ? '#f22020' : lead.leadStatus === 'Dead' ? '#f22020' : 'grey'}}>{lead.leadStatus.toUpperCase()}</span>
                      <span style={{ paddingRight: '20px', color: 'rgba(0,0,0,0.7)' }}>View <OpenInNewIcon style={{ position: 'absolute', width: '18px', margin: '-3px 0 0 4px', color: 'rgba(0,0,0,0.7)' }} /></span>
                    </Typography>
                    </VehicleLeadBox>
                  ))
                ) : ('')}
                </ContentSection>
                <MessageSection>
                  <MessageScroll ref={messageListRef}>
                    <MessageList>
                      {group.messages.map((msg) => (
                        <ListItem key={`${msg.id}-${msg.postedAt}`} style={{ paddingTop: '0', paddingBottom: '4px'}}>
                          <MessageBubble key={`${msg.id}-${msg.postedAt}`} $ownMessage={msg.sender === 'CBO'}>
                            <Typography variant="body2">{msg.text.charAt(0).toUpperCase() + msg.text.slice(1).toLowerCase()}</Typography>
                            <Subtext $ownMessage={msg.sender === 'CBO'}>
                              <span>{formatMessageDate(msg.postedAt)}</span>
                            </Subtext>
                          </MessageBubble>
                        </ListItem>
                      ))}
                    </MessageList>
                    {isSending && <CircularProgress size={20} style={{ color: '#007FFF', float: 'right', margin: '5px 5px' }} />}
                  </MessageScroll>
                  <CustomTextField
                    fullWidth
                    placeholder="Type a message"
                    variant="outlined"
                    value={newMessage}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={e => e.key === 'Enter' && handleSendSMS(group.phoneNumber)}
                    sx={{ mt: 2 }}
                    autoFocus
                    multiline
                    rows={3} // Set an initial height of 3 rows
                    InputProps={{
                      style: { resize: 'vertical', fontSize: '15px', lineHeight: '18px' }, // Allow vertical resizing if desired
                    }}
                  />
                  <StyledMessageButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSendSMS(group.phoneNumber);
                    }}
                    sx={{ mt: 1 }}
                  >
                    Send SMS
                  </StyledMessageButton>
                </MessageSection>
              </ContentBox>
            </Collapse>
          </ExpandableContainer>
        );
      })}

    {loading && (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
      </Box>
    )}

    {!hasMore && !loading && (
      <Box textAlign="center" mt={4}>
        <Typography variant="body1">No more messages to load.</Typography>
      </Box>
    )}

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm SMS</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to send the following SMS to: <strong>{selectedPhoneNumber}</strong>
            <Box mt={2} p={1} bgcolor="#f0f0f0" borderRadius={1}>
              <Typography variant="body2">{newMessage}</Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={confirmSendSMS} color="primary">Send SMS</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SMSHistory;
