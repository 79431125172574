import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { User, useAuth } from '../../context/AuthContext';
import { formatMessageDate } from '../../utils/formatDateTimeUtils';

// Delete message components
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Message icons
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import PhoneMissedOutlinedIcon from '@mui/icons-material/PhoneMissedOutlined';
import MmsOutlinedIcon from '@mui/icons-material/MmsOutlined';
import { API_PREFIX } from '../../constants/urls';

const MessagesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 288px;
  @media all and (max-width: 799px){
    width: 100%;
  }
`;
const NotesHeading = styled(Typography)`
&&{
  font-size: 16px;
  line-height: 1.2;
}`;

interface MessageListProps {
  $isExpanded: boolean; // Notice the $ prefix to mark it as transient
}
const MessageList = styled.div<MessageListProps>`
  width: 288px;
  height: 242px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 6px 8px 4px;
  border: 1px solid #E9E9E9;
  border-radius: 14px;
  margin-bottom: ${props => props.$isExpanded ? '10px' : '-15px'};
  cursor: pointer;
  @media all and (max-width: 799px){
    width: 100%;
    height: 199px;
  }
`;
const InputArea = styled(Box)<{ isVisible: boolean }>`
  height: 164px;
  margin-bottom: -6px;
`;
const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    background: '#FFF',
    padding: '0 6px 0 0'
  },
});
const StyledMessageButton = styled(Button)`
  && {
    width: 100px;
    margin: 8px 0 0;
    float: left;
    border: 2px solid #B9E123;
    font-weight: 600;
    &:hover{
      color: white;
      background: #B9E123;
    }
  }
`;
const NoNotes = styled(Typography)`
&&{
  color: lightgrey;
  text-align: center;
  font-size: 14px;
  margin-top: 15px;
  user-select: none;
}`;

const MessageText = styled.div`
  margin: 0 0 2px;
`
const Subtext = styled.span`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
`;

const Sender = styled.span`
  text-align: left;
`;

const TimeStamp = styled.span`
  text-align: right;
`;

interface MessageBubbleProps {
  $systemMessage: boolean; // Notice the $ prefix to mark it as transient
  $ownMessage: boolean; 
}

const MessageBubble = styled.div<MessageBubbleProps>`
  position: relative; // Allows absolute positioning of the delete icon within this div
  max-width: 87%;
  margin: ${props => props.$ownMessage ? '0 0 2px 13%' : '0 13% 2px 0'};
  color: ${props => props.$ownMessage ? '#FFF' : '#000'};
  background-color: ${props => props.$systemMessage ? '#F6F6F6' : props.$ownMessage ? '#ACD513' : '#F2F9D5'}; //#EDF4D0 
  align-self: ${props => props.$ownMessage ? 'flex-end' : 'flex-start'};
  padding: 5px 10px 1px;
  border-radius: 12px;
  font-size: 13px;
  line-height: 1.2;
  cursor: initial;
  &:hover {
    .delete-icon {
      display: ${props => props.$ownMessage ? 'block' : 'none'}; // Show delete icon on hover
    }
  }
  .delete-icon {
    display: none;
    position: absolute;
    top: 0px;
    right: 8px;
    color: white;
    width: 16px;
    cursor: pointer;
  }
`;

interface Message {
  id: string;
  text: string;
  noteType: string;
  sender: string;
  postedAt: string;
  status: string;
}

interface NotesSectionProps {
  leadId: string;
  user: User | null;
  hideSensitive: boolean;
  notes: Message[];
  fetchNotes: () => void;
  isNotesExpanded: boolean;
}

const NotesSection: React.FC<NotesSectionProps> = ({ leadId, user, hideSensitive, notes, fetchNotes, isNotesExpanded }) => {
  const [messages, setMessages] = useState<Message[]>(notes);
  const [newMessage, setNewMessage] = useState('');
  const messageListRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(isNotesExpanded);

  useEffect(() => {
    setMessages(notes);
  }, [notes]);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
    console.log('Messages: ', messages);
  }, [messages]);

  // Handle adding a user-typed message
  const handleAddMessage = async () => {
    if (!newMessage.trim()) return;

    const tempId = uuidv4(); // Generate a temporary UUID
    const newNote = {
      id: tempId, // Temporary ID
      text: newMessage,
      noteType: 'Note',
      sender: user?.name || 'Unknown',
      postedAt: new Date().toISOString(),
      status: 'sending' // Mark as sending until confirmed
    };

    // Optimistically add the message to the UI
    setMessages(prev => [...prev, newNote]);
    setNewMessage('');

    try {
      const response = await axios.post(`${API_PREFIX}/add-note`, {
        leadId,
        text: newMessage,
        sender: user?.name || 'Unknown'
      }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });

      // Update the temporary ID with the permanent one from the server
      const permanentId = response.data.id; // Assume the server sends back the permanent ID
      setMessages(prev =>
        prev.map(msg => msg.id === tempId ? { ...msg, id: permanentId, status: 'sent' } : msg)
      );
    } catch (error) {
      console.error('Failed to send message', error);
      // Optionally handle failed send attempt
      setMessages(prev => prev.map(msg => {
        if (msg.id === tempId) {
          return { ...msg, status: 'failed' };
        }
        return msg;
      }));
    }
  };

  // Handle a user deleting a message
  const [dialogOpen, setDialogOpen] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState<string | null>(null);

  // Function to initiate the delete process
  const openDeleteDialog = (messageId: string) => {
    setMessageToDelete(messageId);
    setDialogOpen(true);
  };

  // Function to delete the message
  const handleDeleteMessage = async () => {
    if (!messageToDelete) {
      console.error('Delete action initiated without a selected message.');
      return;
    }

    const message = messages.find(msg => msg.id === messageToDelete);
    if (!message) {
      console.error('Message not found:', messageToDelete);
      // Optionally, refresh the messages from the server here to sync state
      fetchNotes();
      return;
    }

    try {
      const response = await axios.delete(`${API_PREFIX}/notes/${leadId}/${messageToDelete}`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });

      if (response.status === 200) {
        setMessages(currentMessages => currentMessages.filter(msg => msg.id !== messageToDelete));
        console.log('Message deleted successfully:', messageToDelete);
      } else {
        console.error('Failed to delete message with status:', response.status);
      }
    } catch (error) {
      console.error('Failed to delete message', error);
    }

    setDialogOpen(false);
    setMessageToDelete(null);
  };

  // Function to close the dialog box
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <MessagesContainer key={`lead-${leadId}`}>
      {/* Confirmation Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this note?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDeleteMessage} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <MessageList ref={messageListRef} $isExpanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)} className="scrollable-div">
        {hideSensitive ? (
          <NoNotes>Not visible</NoNotes>
        ) : (
          messages.length < 1 ? (
            <NoNotes>No notes</NoNotes>
          ) : (
          messages.map(({ id, text, noteType, sender, postedAt, status }, index) => (
            <MessageBubble key={`message-${leadId}-${index}`} $systemMessage={sender === 'System'} $ownMessage={user?.name === sender} >
              <MessageText>
                { noteType === 'SystemPhotoSMS' ? (<><MmsOutlinedIcon style={{ fontSize: '14px', margin: '0 4px -4px 0' }} /></>) 
                : noteType === 'SystemEmail' || noteType === 'Email' ? (<><ForwardToInboxOutlinedIcon style={{ fontSize: '14px', margin: '-1px 4px -3px 0' }} /></>)
                : noteType === 'SystemPhotos' ? (<><PhotoOutlinedIcon style={{ fontSize: '14px', margin: '-2px 4px -2px 0' }} /></>)
                : noteType === 'Call' ? (<><PhoneForwardedOutlinedIcon style={{ fontSize: '14px', margin: '0 4px -4px 0' }} /></>)
                : noteType === 'AttemptedCall' ? (<><PhoneMissedOutlinedIcon style={{ fontSize: '14px', margin: '0 4px -4px 0' }} /></>)
                : ''}
                {text && text}
              </MessageText>
              <Subtext>
                <Sender>{user?.name !== sender && (`${sender}`)}</Sender>
                <TimeStamp>{formatMessageDate(postedAt)} {status === 'sending' && <CircularProgress size={10} />}</TimeStamp>
              </Subtext>
              <DeleteIcon className="delete-icon" onClick={() => openDeleteDialog(id)} />
            </MessageBubble>
          ))
        )
      )}
      </MessageList>
      {isExpanded && (
        <InputArea isVisible={isExpanded}>
          <CustomTextField
            fullWidth
            label="Add a note"
            variant="outlined"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => { if (e.key === 'Enter') handleAddMessage(); }}
            autoFocus
            multiline
              rows={4} // Set an initial height of 2 rows
              InputProps={{
                style: { resize: 'vertical', fontSize: '15px', lineHeight: '18px' }, // Allow vertical resizing if desired
              }}
          />
          <StyledMessageButton onClick={handleAddMessage}>Add Note</StyledMessageButton>
        </InputArea>
      )}
    </MessagesContainer>
  );
};

export default NotesSection;